import { Component, OnInit, Input, ViewChild, EventEmitter, Output, ElementRef } from "@angular/core"
import { EnvironmentConfig, ImageUtils } from "../../../core/core"
import { ModalService } from "../../../core/services/modal.service"
import { ToastrService } from "ngx-toastr"
import { InventoryService } from "../../services/inventory.service"
import { DomSanitizer } from "@angular/platform-browser"
import Cropper from "cropperjs/dist/cropper.esm.js"
import { EditInventoryImagesComponent } from "../edit-inventory-images/edit-inventory-images.component"

declare var environment: EnvironmentConfig

@Component({
  selector: "common-inventory-images",
  templateUrl: "./inventory-images.component.html",
  styleUrls: ["./inventory-images.component.scss"],
})
export class InventoryImagesComponent implements OnInit {
  @Input() inventoryItem: any
  @Input() showAdminActions = true

  @Output() imageUploaded = new EventEmitter<number>()
  @Output() imageDeleted = new EventEmitter<any>()

  @ViewChild("fileInput") fileInput: ElementRef
  @ViewChild("modalContent") modalContent: HTMLElement
  @ViewChild("croppr") croppr: HTMLElement

  images: Array<{ name: string; path: string }> = []
  imgCropperSrc: string
  cropInstance: any
  currentEditIndex: number
  imageQueue: File[] = []

  constructor(
    private inventoryService: InventoryService,
    private toastrService: ToastrService,
    private domSanitizer: DomSanitizer,
    private modalService: ModalService,
  ) {}

  ngOnInit() {
    this.loadImages()
  }

  loadImages() {
    this.inventoryService.getImages(this.inventoryItem.id, this.inventoryItem.accountId).subscribe((result) => {
      this.images = result.map((i, index) => ({
        name: `Listing Photo #${index + 1}`,
        path: i?.storageModel?.storagePath ? `${environment.baseImagePath}/${i.storageModel.storagePath}` : "",
      }))
    })
  }

  onFileSelected(event: Event) {
    const files = (event.target as HTMLInputElement).files
    this.processFiles(files)
  }

  onDragOver(event: DragEvent) {
    event.preventDefault()
    event.stopPropagation()
  }

  onDrop(event: DragEvent) {
    event.preventDefault()
    event.stopPropagation()
    const files = event.dataTransfer.files
    this.processFiles(files)
  }

  processFiles(files: FileList) {
    if (files && files.length > 0) {
      const remainingSlots = 10 - this.images.length
      this.imageQueue = Array.from(files).slice(0, remainingSlots)
      this.processNextImage()
    }
  }

  processNextImage() {
    if (this.imageQueue.length > 0) {
      const file = this.imageQueue.shift()
      ImageUtils.resizeImage({
        file: file,
        maxSize: 1600,
      })
        .then((resizedImage: Blob) => {
          const objectUrl = window.URL.createObjectURL(resizedImage)
          this.imgCropperSrc = this.domSanitizer.bypassSecurityTrustUrl(objectUrl) as any
          this.currentEditIndex = this.images.length
          this.modalService.largeModal(this.modalContent, true).result.then(
            () => {
              // Modal closed, process next image
              this.processNextImage()
            },
            () => {
              // Modal dismissed, process next image
              this.processNextImage()
            },
          )
        })
        .catch((err) => {
          console.error(err)
          this.toastrService.error("Error processing image")
          this.processNextImage()
        })
    }
  }

  imageLoadedForCropper(event) {
    if (this.cropInstance) {
      this.cropInstance.destroy()
    }
    this.cropInstance = new Cropper(event.target, {
      viewMode: 1,
      dragMode: "move",
      aspectRatio: 16 / 9,
      restore: false,
      guides: true,
      center: false,
      highlight: true,
      background: false,
      cropBoxMovable: false,
      cropBoxResizable: false,
      toggleDragModeOnDblclick: false,
    })

    setTimeout(() => {
      const containerData = this.cropInstance.getContainerData()
      this.cropInstance.zoomTo(0, {
        x: containerData.width / 2,
        y: containerData.height / 2,
      })
    }, 800)
  }

  zoomOut() {
    this.cropInstance.zoom(-0.1)
  }

  zoomIn() {
    this.cropInstance.zoom(0.1)
  }

  saveCroppedPhoto(modal) {
    this.cropInstance.getCroppedCanvas().toBlob((blob) => {
      const file = new File([blob], `listing_photo_${this.images.length + 1}.jpg`, { type: "image/jpeg" })
      this.saveAndUploadImage(file)
      modal.close()
    })
  }

  saveAndUploadImage(file: File) {
    const newIndex = this.images.length
    this.uploadImage(file, newIndex)
  }

  imgOnLoad(event) {
    if (event.target.width < event.target.height) {
      this.toastrService.error(
        "Landscape(horizontal) photo is required for listing photos. Please step back and rotate your phone while taking the photo.",
      )
      return
    }
  }

  uploadImage(image: File, index: number) {
    this.inventoryService.uploadImage(this.inventoryItem.id, this.inventoryItem.accountId, index, image).subscribe(
      (result) => {
        this.images.push({
          name: `Listing Photo #${index + 1}`,
          path: result.fullSize,
        })
        this.toastrService.success("Successfully uploaded photo")
        this.imageUploaded.emit(this.images.length)
      },
      (error) => {
        this.toastrService.error("Failed to upload photo")
      },
    )
  }

  updateCoverImage(index: number) {
    this.inventoryService.updateCoverImage(this.inventoryItem.id, this.inventoryItem.accountId, index).subscribe(
      (result) => {
        this.inventoryItem.coverImagePosition = index
        this.toastrService.success("Successfully set cover photo")
      },
      (error) => {
        this.toastrService.error("Failed to set cover photo")
      },
    )
  }

  editImage(image: { name: string; path: string }, index: number) {
    this.currentEditIndex = index
    const modalRef = this.modalService.largeModal(EditInventoryImagesComponent, true)
    modalRef.componentInstance.originalImageSource = image.path

    modalRef.result.then(
      (blob) => {
        const file = new File([blob], image.name, { type: "image/jpeg" })
        this.uploadImage(file, index)
      },
      () => {},
    )
  }

  deleteImage(index: number) {
    this.inventoryService.deleteImage(this.inventoryItem.id, this.inventoryItem.accountId, index).subscribe(
      (result) => {
        this.images.splice(index, 1)
        this.toastrService.success("Successfully deleted photo")
        this.imageDeleted.emit()
      },
      (error) => {
        this.toastrService.error("Failed to delete photo")
      },
    )
  }

  downloadAllPhotos() {
    this.inventoryService.downloadAllPhotos(this.inventoryItem.id).subscribe(
      () => {
        this.toastrService.success("Photos download initiated")
      },
      (error) => {
        this.toastrService.error("Failed to download photos")
      },
    )
  }
}

