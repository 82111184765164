import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { AccountBadges } from "../../models/account.model";

@Component({
  selector: "common-top-owner",
  templateUrl: "./top-owner.component.html",
  styleUrls: ["./top-owner.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopOwnerComponent {
  @Input() badges: AccountBadges[];
  @Input() showTopOwner: boolean;
  @Input() icon: string = "icon-rubbl";

  @Input() text: string = "Top Owner";
  @Input() theme: string = "dark";

  constructor() {}

  ngOnInit() {
    if (this.badges?.includes(AccountBadges.TopOwner)) {
      this.showTopOwner = true;
    }
  }
}
