import { ChangeDetectionStrategy, Component } from "@angular/core";
import { AuthenticationService } from "../../../core/core";

@Component({
  selector: "app-header-list-machine",
  template: `<a type="button" class="btn btn-outline-primary br-0 list-your-machine d-none d-md-block" [routerLink]="link">List a machine</a>`,
  styleUrls: ["./header-list-machine.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderListMachineComponent {
  link = ["lp", "list-your-machines"];

  constructor(private authenticationService: AuthenticationService) {
    const user = authenticationService.getUserNonAsync();
    if (user) {
      this.link = ["owner", "fleet", "create"];
    }
  }
}
