import { Component, OnInit, OnDestroy, Input, ComponentFactoryResolver, ViewChild, ViewContainerRef } from "@angular/core";
import { MachineCategoryCardComponent } from "../../../inventory/components/machine-category-card/machine-category-card.component";
import { SwiperConfigInterface, SwiperDirective } from "ngx-swiper-wrapper";
import { Subscription } from "rxjs";
import { WindowService } from "../../../core/services/window.service";
import { MachineCardAdvancedComponent } from "../../../cart/components/machine-card-advanced/machine-card-advanced.component";
import { BuyOnlyMachineCardAdvancedComponent } from "../../../cart/components/buy-only-machine-card-advanced/buy-only-machine-card-advanced.component";

@Component({
  selector: "app-card-carousel",
  templateUrl: "./card-carousel.component.html",
  styleUrls: ["./card-carousel.component.scss"],
})
export class CardCarouselComponent implements OnInit, OnDestroy {
  @Input() header: string;
  @Input() slidesPerViewConfig = {
    mobile: 2,
    tablet: 3,
    desktop: 4,
    "large-desktop": 5,
    "extra-large-desktop": 5,
    "extra-extra-large-desktop": 5,
  };

  @Input() cardComponentName: string;
  @Input() cardComponentNames: string[];
  @Input() cardData: any[];
  index = 0;

  @ViewChild(SwiperDirective, { static: false }) directiveRef?: SwiperDirective;
  @ViewChild("swiperContainer", { read: ViewContainerRef, static: true }) swiperContainer;

  config: SwiperConfigInterface = {
    direction: "horizontal",
    slidesPerView: 5,
    keyboard: true,
    mousewheel: false,
    scrollbar: false,
    navigation: false,
    pagination: false,
    spaceBetween: 15,
    lazy: {
      preloaderClass: "preloader",
    },
    fadeEffect: {
      crossFade: true,
    },
  };

  componentMap = {
    MachineCategoryCardComponent: MachineCategoryCardComponent,
    MachineCardAdvancedComponent: MachineCardAdvancedComponent,
    BuyOnlyMachineCardAdvancedComponent: BuyOnlyMachineCardAdvancedComponent,
  };

  windowSizeSubscription: Subscription;

  constructor(private windowService: WindowService, private componentFactoryResolver: ComponentFactoryResolver) {}

  ngOnInit() {
    this.loadComponents();
    this.windowSizeSubscription = this.windowService.windowSizeBSubject.subscribe(windowSize => {
      this.config.slidesPerView = this.slidesPerViewConfig[windowSize];
    });
  }

  ngOnDestroy() {
    this.windowSizeSubscription.unsubscribe();
  }

  loadComponents() {
    if (this.cardComponentName) {
      const factory = this.componentFactoryResolver.resolveComponentFactory(this.componentMap[this.cardComponentName]);
      for (let data of this.cardData) {
        const compRef = this.swiperContainer.createComponent(factory);
        compRef.location.nativeElement.className = "swiper-slide";

        for (let key in data) {
          compRef.instance[key] = data[key];
        }
      }

      return;
    }

    if (this.cardComponentNames) {
      for (let i = 0; i < this.cardComponentNames.length; i++) {
        const factory = this.componentFactoryResolver.resolveComponentFactory(this.componentMap[this.cardComponentNames[i]]);
        const compRef = this.swiperContainer.createComponent(factory);
        compRef.location.nativeElement.className = "swiper-slide";
        for (let key in this.cardData[i]) {
          compRef.instance[key] = this.cardData[i][key];
        }
      }
    }
  }

  next() {
    this.directiveRef.nextSlide();
  }

  previous() {
    this.directiveRef.prevSlide();
  }
}
