import { Component } from "@angular/core";

import { Router } from "@angular/router";
import { SearchRequest } from "../../models/search-request.model";
import { IpGeoLocationService, IpInfo, LocalStorageService, Location, LogService, MarketTrackingEvents } from "../../../core/core";
import { SearchResultsService } from "../../services/search-results.service";
import { AzureSuggestsResult } from "../../../azure-search/azure-search";

@Component({
  selector: "common-home-search",
  templateUrl: "./home-search.component.html",
  styleUrls: ["./home-search.component.scss"],
})
export class HomeSearchComponent {
  headlines: string[] = [
    "Heavy Equipment Rentals, Simplified",
    "The Smarter Way to Rent Equipment",
    "The Future of Equipment Rentals is Here",
    "Rent Smarter, Faster, and Easier Than Ever",
    "A Free Marketplace for the Best Rental Deals",
    "Find and Book Rentals in Minutes, Not Hours",
    "Instant Equipment Rentals at Your Fingertips",
    "Browse, Compare, Rent—It’s That Easy",
    "Your One-Click Solution to Equipment Rentals",
    "The Marketplace for Effortless Equipment Rentals",
  ];
  currentHeadline: string;

  inputFocused: boolean;
  searchRequest: SearchRequest = new SearchRequest();

  constructor(
    private router: Router,
    private searchResultsService: SearchResultsService,
    private logService: LogService,
    private localStorageService: LocalStorageService,
    private ipGeoLocationService: IpGeoLocationService,
  ) {}

  ngOnInit() {
    this.searchRequest.location.location = new Location();
    this.searchRequest.location.location.address.address1 = "Current Location";
    this.searchRequest.location.location.latitude = 1;
    this.searchRequest.location.location.longitude = 1;

    const userLocationIp = this.localStorageService.get("user-location-ip", true);
    if (userLocationIp) {
      this.searchRequest.location.location = new Location();
      this.searchRequest.location.location.address.city = userLocationIp.city;
      this.searchRequest.location.location.address.stateProvince = this.ipGeoLocationService.getStateCode(userLocationIp.region);
      this.searchRequest.location.location.longitude = +userLocationIp.loc.split(",")[1];
      this.searchRequest.location.location.latitude = +userLocationIp.loc.split(",")[0];
      this.searchResultsService.storeLocation(this.searchRequest.location.location);
    } else {
      this.ipGeoLocationService.getUserLocation().subscribe((ipInfo: IpInfo) => {
        this.searchRequest.location.location = new Location();
        this.searchRequest.location.location.address.city = ipInfo.city;
        this.searchRequest.location.location.address.stateProvince = this.ipGeoLocationService.getStateCode(ipInfo.region);
        this.searchRequest.location.location.longitude = +ipInfo.loc.split(",")[1];
        this.searchRequest.location.location.latitude = +ipInfo.loc.split(",")[0];
        this.localStorageService.set("user-location-ip", ipInfo, true);

        this.searchResultsService.storeLocation(this.searchRequest.location.location);
      });
    }

    const randomIndex = Math.floor(Math.random() * this.headlines.length);
    this.currentHeadline = this.headlines[randomIndex];
  }

  suggestionSelected($event: AzureSuggestsResult) {
    this.searchRequest.searchTerm = $event.value;
    this.searchRequest.selectedSuggestion = $event;
    // when suggestion is selected lets automatically focus to the location
    // reason for timeout is to wait for the search sugggests animation to finish
    setTimeout(() => {
      document.querySelector("#typeahead-basic")["focus"]();
    }, 250);
  }

  locationSelected(event: Location) {
    this.searchRequest.location.location = event;

    // store this so we can look up gps coordinates
    this.searchResultsService.storeLocation(event);
  }

  search() {
    this.searchResultsService.clearSearchResults();
    const queryParams = this.searchRequest.queryParams;
    this.router.navigate([this.searchRequest.searchResultsUrl()], {
      queryParams,
    });

    this.logService.trackEvent(
      MarketTrackingEvents.Home.SearchButtonClick,
      {
        event: "rentalSearch",
        searchTerm: this.searchRequest.searchTerm,
      },
      true,
    );
  }
}
