import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from "@angular/core";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { RateSchedule } from "../../../../inventory/inventory";

@Component({
  selector: "common-add-to-cart-dates",
  templateUrl: "./add-to-cart-dates.component.html",
  styleUrls: ["./add-to-cart-dates.component.scss"],
})
export class AddToCartDatesComponent implements OnInit {
  @Input() startDate: NgbDate;
  @Input() duration: number = 7;
  @Input() zipcode: string;
  @Input() rpoEnabled: boolean;
  @Input() rpoInterested: boolean;
  @Input() rateSchedules: RateSchedule[];
  @Input() blockWeekends: boolean = true;
  @Input() showZipCode: boolean = true;

  inCheckout: boolean;
  invalidZipCode: boolean;

  @Output() startDateSelected = new EventEmitter<NgbDate>();
  @Output() durationSelected = new EventEmitter<string>();
  @Output() zipcodeChange = new EventEmitter<number>();
  @Output() rpoSelected = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {
    // hack but it works
    this.inCheckout = window.location.pathname.includes("checkout");
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.zipcode && changes.zipcode.firstChange == false && changes.zipcode.currentValue === "00000") {
      this.invalidZipCode = true;
      this.zipcode = changes.zipcode.previousValue;
    }
  }

  onStartDateSelected(date: NgbDate) {
    this.startDateSelected.emit(date);
  }

  onDurationSelected(duration: string) {
    this.durationSelected.emit(duration);
  }

  onZipcodeChange(zipcode: number) {
    this.zipcodeChange.emit(zipcode);
    this.invalidZipCode = false;
  }

  rpoChange(event) {
    this.rpoInterested = event;
    this.rpoSelected.emit(event);
  }
}
