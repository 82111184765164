<div class="d-lg-flex flex-wrap align-items-center pt-4" rt="inventory-images-header">
  <h3>Listing Photos</h3>
  <div class="ml-auto" *ngIf="showAdminActions">
    <common-icon-button rt="inventory-images-download-photo" iconClass="icon-import" buttonText="Download all photos"
      (click)="downloadAllPhotos()">
    </common-icon-button>
  </div>
</div>

<ol class="font-weight-light">
  <li>Include all angles of the machine</li>
  <li>Make sure to step back far away to capture the whole machine</li>
  <li>Maximum of 10 images allowed</li>
</ol>

<div class="row">
  <div class="col-lg-6 col-xl-3 mt-3" *ngFor="let image of images; index as i">
    <div class="card">
      <div class="card-header d-flex">
        <div>
          {{ image.name }}
          <span *ngIf="inventoryItem.coverImagePosition == i">(Cover photo)</span>
        </div>
        <div class="ml-auto" *ngIf="image.path">
          <div ngbDropdown placement="bottom-right" class="d-inline-block">
            <i class="icon-peapod text-green cursor-pointer" ngbDropdownToggle></i>
            <div ngbDropdownMenu>
              <button ngbDropdownItem (click)="updateCoverImage(i)">Make Cover</button>
              <button ngbDropdownItem *ngIf="showAdminActions" (click)="editImage(image, i)">Edit</button>
              <button ngbDropdownItem (click)="deleteImage(i)">Delete</button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <img class="w-100 img-fit br-5" *ngIf="image.path" [src]="image.path" (load)="imgOnLoad($event)" />
      </div>
    </div>
  </div>

  <div class="col-lg-6 col-xl-3 mt-3" *ngIf="images.length < 10">
    <div class="card">
      <div class="card-body">
        <div class="d-flex justify-content-center align-items-center image-thumbnail-add" (click)="fileInput.click()"
          (dragover)="onDragOver($event)" (drop)="onDrop($event)">
          <figure>
            <i class="icon-large icon-stepper-add text-light-grey ml-3 mr-1 cursor-pointer text-green"
              rt="upload-image-component-add-image"></i>
            <figcaption>Add Photos</figcaption>
          </figure>
        </div>
      </div>
    </div>
  </div>
</div>

<input #fileInput type="file" (change)="onFileSelected($event)" multiple accept="image/*" style="display: none;" />

<ng-template #modalContent let-modal>
  <div class="modal-header">
    <button type="button" class="btn btn-outline-secondary" data-dismiss="modal"
      (click)="modal.dismiss()">Close</button>
    <button type="button" class="btn btn-secondary" (click)="saveCroppedPhoto(modal)">Save photo</button>
  </div>

  <img style="max-width: 100%" [src]="imgCropperSrc" id="croppr" #croppr (load)="imageLoadedForCropper($event)" />

  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" data-dismiss="modal" (click)="zoomOut()">Zoom Out</button>
    <button type="button" class="btn btn-outline-secondary" (click)="zoomIn()">Zoom In</button>
  </div>
</ng-template>