import { CountValueFacetItem, Facet } from "./facet.model";
import { Location } from "../../../core/core";
import { DropDownItem } from "../../../forms/forms";

export class LocationFacet extends Facet {
  location: Location;
  userAllowedCurrentLocation: boolean = false;
  selectedRadius: string = "100";

  radiusOptions: DropDownItem[] = [
    {
      label: "Within 50 miles",
      value: "50",
      action: () => {
        this.selectedRadius = "50";
      },
    },
    {
      label: "Within 100 miles",
      value: "100",
      action: () => {
        this.selectedRadius = "100";
      },
    },
    {
      label: "Within 250 miles",
      value: "250",
      action: () => {
        this.selectedRadius = "250";
      },
    },
    {
      label: "Within 500 miles",
      value: "500",
      action: () => {
        this.selectedRadius = "500";
      },
    },
    {
      label: "Within 1,000 miles",
      value: "1000",
      action: () => {
        this.selectedRadius = "1000";
      },
    },
    {
      label: "Anywhere",
      value: "anywhere",
      action: () => {
        this.selectedRadius = "anywhere";
      },
    },
  ];

  constructor(name: string, urlParams?: URLSearchParams) {
    super(name, []);
    // if we have url params when creating the class, we need to "filter" so that we can get the location from paarams
    if (urlParams) {
      this.filter(urlParams);
    }
  }

  get selected(): boolean {
    return this.location != null;
  }

  newFilter(location: Location) {
    if (!location && !this.location) {
      return;
    }

    if (location) {
      this.location = location;
    }

    const urlParams = new URLSearchParams(window.location.search);
    const param = urlParams.get("radius");
    if (!param) {
      this.selectedRadius = "100";
    } else {
      this.selectedRadius = param;
    }

    if (!this.selectedRadius || this.selectedRadius === "anywhere") {
      // if we do not have a selected radios just filter by 80,000 km
      return `(geo.distance(location/point, geography'POINT(${this.location.longitude}
                ${this.location.latitude})') le 80000)`;
    }

    // reason for math.ceil is azure search does not like a lot of digits
    // dividing by .62 give you the km. azure map filters in km and not miles
    return `(geo.distance(location/point, geography'POINT(${this.location.longitude}
               ${this.location.latitude})') le ${Math.ceil(+this.selectedRadius / 0.62137)})`;
  }

  filter(urlParams: URLSearchParams): string {
    const param = urlParams.get(this.name);
    // gotta update this logic
    if (!param) {
      if (this.selectedRadius === "anywhere") {
        this.selectedRadius = "anywhere";

        // if we dont have a query param then we can assume its the users location
        if (this.location?.latitude && this.location?.longitude) {
          return `(geo.distance(location/point, geography'POINT(${this.location.longitude}
                ${this.location.latitude})') le 80000)`;
        }
      }

      if (this.location?.latitude && this.location?.longitude) {
        return `(geo.distance(location/point, geography'POINT(${this.location.longitude}
              ${this.location.latitude})') le ${Math.ceil(+this.selectedRadius / 0.62137)})`;
      }

      return null;
    }

    const [cityState, longlat, radius] = param.split(":");
    const [city, state] = cityState.split(",");
    const [long, lat] = longlat.split(",");

    this.location = new Location();
    this.location.address.city = city;
    this.location.address.stateProvince = state;
    this.location.longitude = +long;
    this.location.latitude = +lat;

    this.selectedRadius = radius;
    if (!this.selectedRadius || this.selectedRadius === "anywhere") {
      // if we do not have a selected radios just filter by 80,000 km
      return `(geo.distance(location/point, geography'POINT(${this.location.longitude}
                ${this.location.latitude})') le ${Math.ceil(+this.selectedRadius / 0.62137)})`;
    }

    // reason for math.ceil is azure search does not like a lot of digits
    // dividing by .62 give you the km. azure map filters in km and not miles
    return `(geo.distance(location/point, geography'POINT(${this.location.longitude}
               ${this.location.latitude})') le ${Math.ceil(+this.selectedRadius / 0.62137)})`;
  }

  getLatLong() {
    // if we have a location it most likely means we have their current user location
    if (this.location?.latitude && this.location?.longitude) {
      return [this.location.longitude, this.location.latitude];
    }

    const param = new URLSearchParams(window.location.search).get(this.name);
    if (!param) {
      return null;
    }

    const [cityState, longlat, radius] = param.split(":");
    const [long, lat] = longlat.split(",");
    return [+long, +lat];
  }

  uncheck = () => {
    this.location = null;
    this.selectedRadius = "1000";
  };

  clear = () => {
    this.location = null;
  };

  update = (facets: CountValueFacetItem[]) => {
    // if this is selected do not update
    if (this.selected) {
      return;
    }
    console.error("need to update this logic");
    this.facets = facets;
  };

  toCityState = (): string => {
    if (!this.selected) {
      return "";
    }

    return `${this.location.address.city}, ${this.location.address.stateProvince}`;
  };

  toCityStateSeo = (): string => {
    if (!this.selected) {
      return "";
    }

    return `${this.location.address.city.replace(" ", "-")}-${this.location.address.stateProvince}`;
  };

  toQueryParam = (): string => {
    if (!this.selected) {
      return undefined;
    }

    const radius = this.selectedRadius === "100" ? undefined : `${this.selectedRadius}`;
    return radius;
  };
}
